import Access from '@/services/Access'
import Features from '@/services/Features'
import {toggleHelpBubble} from "@core/layouts/utils"

const menuItems = [
    {
        title: 'Dashboards',
        icon: 'PieChartIcon',
        visible: true,
        children: [
            {
                title: 'Linnworks',
                route: 'dashboard.linnworks',
                visible: Access.checkAccess(
                    ['linnworks_integration.process_order'],
                    Access.LEVEL_READ,
                ) && Features.checkFeature('linnworks_integration'),
            },
            {
                title: 'Mirakl',
                route: 'dashboard.mirakl',
                visible: Access.checkAccess(
                    ['mirakl_integration.dashboard'],
                    Access.LEVEL_READ) && Features.checkFeature('mirakl_integration'),
            },
            {
                title: 'DB Schenker',
                route: 'dashboard.schenker',
                visible: Access.checkAccess(
                    ['schenker_integration.dashboard'],
                    Access.LEVEL_READ,
                ) && Features.checkFeature('schenker_integration'),
            }
        ]
    },
    {
        title: 'Inventory',
        icon: 'CodesandboxIcon',
        route: 'inventory.index',
        visible:
            Access.checkAccess('inventory', Access.LEVEL_READ)
            && Features.checkFeature('inventory'),
    },
    {
        title: 'Orders',
        icon: 'ShoppingCartIcon',
        route: 'orders.index',
        visible:
            Access.checkAccess('orders', Access.LEVEL_READ)
            && Features.checkFeature('orders'),
    },
    {
        title: 'Shipments',
        icon: 'TruckIcon',
        visible: Access.checkAccess(
            ['settings.users', 'settings.project', 'settings.user_groups'],
            Access.LEVEL_READ,
        ),
        children: [
            {
                title: 'Shipment plans',
                icon: 'ClipboardIcon',
                route: 'shipments.shipmentPlans',
                visible: Access.checkAccess('shipments', Access.LEVEL_READ) && Features.checkFeature('shipment_plans'),
            },
            {
                title: 'Shipments',
                icon: 'TruckIcon',
                route: 'shipments.index',
                visible: Access.checkAccess('shipments', Access.LEVEL_READ),
            },
        ],
    },
    {
        title: 'Settings',
        icon: 'SettingsIcon',
        visible: Access.checkAccess(
            ['settings.users', 'settings.project', 'settings.user_groups'],
            Access.LEVEL_READ,
        ),
        children: [
            {
                title: 'Users',
                icon: 'UserIcon',
                route: 'settings.users',
                visible: Access.checkAccess('settings.users', Access.LEVEL_READ),
            },
            {
                title: 'User Groups',
                icon: 'UsersIcon',
                route: 'settings.user-groups',
                visible: Access.checkAccess(
                    'settings.user_groups',
                    Access.LEVEL_READ,
                ),
            },
            {
                title: 'Project settings',
                icon: 'PlusSquareIcon',
                route: 'settings.project',
                visible: Access.checkAccess(
                    'settings.project',
                    Access.LEVEL_READ,
                ),
            },
            {
                title: 'Custom fields',
                icon: 'SlidersIcon',
                route: 'settings.customFields',
                visible: Access.checkAccess(
                    'settings.customFields',
                    Access.LEVEL_READ,
                ),
            },
            {
                title: 'Integrations',
                icon: 'RepeatIcon',
                route: 'settings.integrations',
                visible: Access.checkAccess('settings.integrations', Access.LEVEL_READ),
            },
            {
                title: 'Inventory',
                icon: 'CodesandboxIcon',
                route: 'settings.inventory',
                visible: Access.checkAccess('settings.inventory', Access.LEVEL_READ) && Features.checkFeature('inventory'),
            },
            {
                title: 'Orders',
                icon: 'ShoppingCartIcon',
                route: 'settings.orders',
                visible: Access.checkAccess('settings.orders', Access.LEVEL_READ) && Features.checkFeature('orders'),
            },
        ],
    },
    {
        title: 'Help',
        icon: 'HelpCircleIcon',
        foo: 'bar',
        action: () => toggleHelpBubble(),
        visible: true,
    },
]

export default menuItems
