<template>
    <div>
        <div v-if="type === 'navbar'"
             style="position: absolute;
                    bottom: 120px;
                    left: 32px;"
        >
            <feather-icon
                size="20"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
                @click="skin = isDark ? 'light' : 'dark'"
                class="text-dark"
            />
        </div>
        <b-nav-item v-if="type === 'header'" @click="skin = isDark ? 'light' : 'dark'">
            <feather-icon
                size="21"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
            />
        </b-nav-item>
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
    components: {
        BNavItem,
    },
    props: {
        type: {
            type: String,
            default: 'header',
        },
    },
    setup() {
        const { skin } = useAppConfig()

        const isDark = computed(() => skin.value === 'dark')

        return { skin, isDark }
    },
}
</script>
