<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            {{ new Date().getFullYear() }} © FULFILL<span style="color: #f89624; !important;">IN</span>
        </span>
    </p>
</template>

<script>

export default {
    components: {
    },
}
</script>
