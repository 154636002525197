<template>
    <div>
        <b-dropdown
            v-if="type === 'navbar'"
            dropright
            variant="flat-primary bg-transparent"
            class="dropdown-icon-wrapper"
            toggle-class="d-flex align-items-center dropdown-user-link"
            style="position: absolute;
            bottom: 0;"
        >
            <template #button-content>
                <b-avatar
                    size="40"
                    variant="light-warning"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                >
                    <span
                        v-if="!userData.fullName"
                        style="font-size: 15px;
                        font-weight: bold;
                        "
                    >
                        {{ avatarTwoLetters() }}
                    </span>
                    <feather-icon
                        v-if="userData.fullName"
                        icon="UserIcon"
                        size="22"
                    />
                </b-avatar>
            </template>
            <b-dropdown-item
                :to="{ name: 'settings.profile'}"
                link-class="d-flex align-items-center"
            >
                <feather-icon
                    size="16"
                    icon="UserIcon"
                    class="mr-50"
                />
                <span>Profile</span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
            >
                <feather-icon
                    size="16"
                    icon="LogOutIcon"
                    class="mr-50"
                />
                <span>Logout</span>
            </b-dropdown-item>
        </b-dropdown>
        <b-nav-item-dropdown
            v-if="type === 'header'"
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
        >
            <template #button-content>
                <b-avatar
                    size="40"
                    variant="light-warning"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                >
                    <span
                        v-if="!userData.fullName"
                        style="font-size: 15px;
                    font-weight: bold;"
                    >
                        {{ avatarTwoLetters() }}
                    </span>
                    <feather-icon
                        v-if="userData.fullName"
                        icon="UserIcon"
                        size="22"
                    />
                </b-avatar>
            </template>

            <b-dropdown-item
                :to="{ name: 'settings.profile'}"
                link-class="d-flex align-items-center"
            >
                <feather-icon
                    size="16"
                    icon="UserIcon"
                    class="mr-50"
                />
                <span>Profile</span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
            >
                <feather-icon
                    size="16"
                    icon="LogOutIcon"
                    class="mr-50"
                />
                <span>Logout</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </div>
</template>

<script>
import {
    BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BDropdown,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BDropdown,
    },
    props: {
        type: {
            type: String,
            default: 'navbar',
        },
    },
    data() {
        return {
            userData: this.$store.getters.user,
            avatarText,
        }
    },
    methods: {
        logout() {
            this.$auth.logout()
        },
        avatarTwoLetters() {
            const userName = this.userData.name.toUpperCase()
            const userNameWordCount = userName.trim().split(/\s+/).length
            if (userNameWordCount === 1 && userName.length <= 2) return userName

            return userName
                .match(/(^(?:\S+\s?){1,3})/g)
                .join()
                .split(/\s/)
                .map(s => s.charAt(0))
                .join('')
        },
    },
}
</script>
