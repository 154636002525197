<template>
    <li
        v-if="item.visible"
        class="nav-item"
        :class="{
            'active': isActive,
            'disabled': item.disabled
        }"
    >

        <template v-if="linkProps.action">
            <a
                @click="linkProps.action"
                class="d-flex align-items-center"
            >
                <feather-icon :icon="item.icon || 'CircleIcon'" />
                <span class="menu-title text-truncate">{{ item.title }}</span>
                <b-badge
                    v-if="item.tag"
                    pill
                    :variant="item.tagVariant || 'primary'"
                    class="mr-1 ml-auto"
                >
                    {{ item.tag }}
                </b-badge>
            </a>
        </template>
        <template v-else>
            <b-link
                v-bind="linkProps"
                class="d-flex align-items-center"
            >
                <feather-icon :icon="item.icon || 'CircleIcon'" />
                <span class="menu-title text-truncate">{{ item.title }}</span>
                <b-badge
                    v-if="item.tag"
                    pill
                    :variant="item.tagVariant || 'primary'"
                    class="mr-1 ml-auto"
                >
                    {{ item.tag }}
                </b-badge>
            </b-link>
        </template>
    </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
    components: {
        BLink,
        BBadge,
    },
    mixins: [mixinVerticalNavMenuLink],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

        return {
            isActive,
            linkProps,
            updateIsActive,

        }
    },

}
</script>
