<template>
    <ul>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            :key="item.header || item.title"
            :item="item"
        />

        <template
            v-if="isNavbarHidden"
        >
            <customizer
                placement="bottom"
            />
            <darkToggler type="navbar" />
            <project-dropdown type="navbar" />
            <user-dropdown type="navbar" />
        </template>
    </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { computed, provide, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import UserDropdown from '../../../../../components/app-navbar/components/UserDropdown.vue'
import DarkToggler from '../../../../../components/app-navbar/components/DarkToggler.vue'
import ProjectDropdown from '../../../../../components/app-navbar/components/ProjectDropdown.vue'
import Customizer from '@/components/Misc/Customizer'

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
        UserDropdown,
        DarkToggler,
        ProjectDropdown,
        Customizer,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const { navbarType } = useAppConfig()

        const isNavbarHidden = computed(() => navbarType.value === 'hidden')
        provide('openGroups', ref([]))

        return {
            resolveNavItemComponent, isNavbarHidden, navbarType,
        }
    },
}
</script>
