<template>
    <div>
        <b-dropdown
            v-if="type === 'navbar'"
            droptop
            variant="flat-dark bg-transparent"
            class="dropdown-icon-wrapper"
            toggle-class="d-flex align-items-center dropdown-user-link"
            style="position: absolute;
            bottom: 60px;
            left: 10px;"
        >
            <template #button-content>
                <feather-icon
                    size="20"
                    icon="ClipboardIcon"
                />
            </template>
            <template v-for="(currentProject, index) in projects">
                <b-dropdown-item :key="index"
                                 link-class="d-flex align-items-center"
                                 @click="switchProject(currentProject)"
                >
                    <span>{{ currentProject.name }}</span>
                </b-dropdown-item>
            </template>
        </b-dropdown>
        <b-nav-item-dropdown
            v-if="type === 'header'"
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
        >
            <template #button-content>
                {{ project.name }}
            </template>

            <template v-for="(currentProject, index) in projects">
                <b-dropdown-item :key="index"
                                 link-class="d-flex align-items-center"
                                 @click="switchProject(currentProject)"
                >
                    <span>{{ currentProject.name }}</span>
                </b-dropdown-item>
            </template>
        </b-nav-item-dropdown>
    </div>
</template>

<script>
import {
    BNavItemDropdown, BDropdownItem, BDropdown,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import State from '@/store'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdown,
    },
    props: {
        type: {
            type: String,
            default: 'header',
        },
    },
    data() {
        return {
            userData: this.$store.getters.user,
            avatarText,
        }
    },
    computed: {
        project() {
            return State.getters.activeProject
        },
        projects() {
            return State.getters.user.projects
        },
    },
    methods: {
        switchProject(project) {
            this.$store.dispatch('changeProject', project)
            this.$router.push({ name: 'dashboard' })
            localStorage.removeItem('lastRoute')
            setTimeout(() => window.location.reload(), 50)
        },
    },
}
</script>
